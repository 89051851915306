import React, { useEffect, useState } from "react";
import permissionService from "./PermissionService";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Card,
  CardContent,
  Grid,
  Box,
  TablePagination,
  FormControlLabel,
  Checkbox,
  Snackbar,
  Alert,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
} from "@mui/material";
import { Edit, Delete } from "@mui/icons-material";
import { styled } from "@mui/material/styles";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";

const CenteredCard = styled(Card)(({ theme }) => ({
  maxWidth: 1500,
  margin: "auto",
  marginTop: theme.spacing(4),
  padding: theme.spacing(2),
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  flex: 1,
  textAlign: "center",
}));

const PermissionManagement = () => {
  const [roles, setRoles] = useState([]);
  const [entities, setEntities] = useState([]);
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState({ open: false, message: "", severity: "success" });

  useEffect(() => {
    fetchRoles();
  }, []);

  const fetchRoles = async () => {
    try {
      const rolesData = await permissionService.getAllRolePermissions();
      const entityList = [
        ...new Set(rolesData.flatMap((role) => role.permissions.map((p) => p.page_name))),
      ];
      setEntities(entityList);
      setRoles(
        rolesData.map((role) => ({
          id: role.id,
          name: role.name,
          permissions: entityList.reduce((acc, entity) => {
            const permission = role.permissions.find((p) => p.page_name === entity);
            acc[entity] = {
              view: permission?.pivot.view === 1,
              add: permission?.pivot.add === 1,
              edit: permission?.pivot.edit === 1,
              delete: permission?.pivot.delete === 1,
              setting: permission?.pivot.setting === 1,
            };
            return acc;
          }, {}),
        }))
      );
    } catch (error) {
      setAlert({
        open: true,
        message: "Error fetching roles and permissions",
        severity: "error",
      });
    }
  };

  const handlePermissionChange = (roleId, entity, type) => {
    setRoles((prevRoles) => {
      return prevRoles.map((role) => {
        if (role.id === roleId) {
          return {
            ...role,
            permissions: {
              ...role.permissions,
              [entity]: {
                ...role.permissions[entity],
                [type]: !role.permissions[entity][type],
              },
            },
          };
        }
        return role;
      });
    });
  };

  const savePermissions = async () => {
    setLoading(true);
    try {
      for (const role of roles) {
        for (const entity of entities) {
          const permissionData = {
            role_id: role.id,
            page_name: entity,
            ...role.permissions[entity],
          };
          await permissionService.updatePermission(role.id, permissionData);
        }
      }
      setAlert({
        open: true,
        message: "Permissions updated successfully",
        severity: "success",
      });
    } catch (error) {
      setAlert({
        open: true,
        message: "Error saving permissions",
        severity: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={2}>
        <Snackbar
          open={alert.open}
          autoHideDuration={6000}
          onClose={() => setAlert({ ...alert, open: false })}
        >
          <Alert severity={alert.severity} onClose={() => setAlert({ ...alert, open: false })}>
            {alert.message}
          </Alert>
        </Snackbar>
        <CenteredCard>
          <CardContent>
            <h3>Manage all roles permissions</h3>
            {roles.map((role) => (
              <Box key={role.id} mb={5}>
                <h4>{role.name}</h4>
                <TableContainer component={Paper}>
                  <Table>
                    <TableRow>
                      <TableCell>Entity</TableCell>
                      <TableCell>View</TableCell>
                      <TableCell>Add</TableCell>
                      <TableCell>Edit</TableCell>
                      <TableCell>Delete</TableCell>
                      <TableCell>Setting</TableCell>
                    </TableRow>
                    <TableBody>
                      {entities.map((entity) => (
                        <TableRow key={entity}>
                          <TableCell>{entity}</TableCell>
                          {["view", "add", "edit", "delete", "setting"].map((type) => (
                            <TableCell key={type}>
                              <Checkbox
                                checked={role.permissions[entity]?.[type] || false}
                                onChange={() => handlePermissionChange(role.id, entity, type)}
                              />
                            </TableCell>
                          ))}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            ))}
            <Button
              variant="contained"
              onClick={savePermissions}
              disabled={loading}
              sx={{
                backgroundColor: "#87CEFA",
                "&:hover": {
                  backgroundColor: "#00BFFF",
                },
              }}
            >
              {loading ? "Please wait..." : "Save Changes"}
            </Button>
          </CardContent>
        </CenteredCard>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
};

export default PermissionManagement;
