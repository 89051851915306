import { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import BasicLayout from "layouts/authentication/components/BasicLayout";
import bgImage from "assets/images/bg-sign-in-basic.jpeg";

function SignUp() {
  const { email } = useParams(); // Récupère l'email depuis l'URL
  const [userData, setUserData] = useState({
    name: "", // Valeur initiale vide, tu pourras pré-remplir si nécessaire
    email: email, // Utilise l'email récupéré dans l'URL
    phone: "",
  });
  const [password, setPassword] = useState(""); // Déclare un état pour le mot de passe
  const [confirmPassword, setConfirmPassword] = useState(""); // Déclare un état pour la confirmation du mot de passe
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const navigate = useNavigate();

  const handleUpdate = async (e) => {
    e.preventDefault(); // Empêche le rechargement de la page

    if (password && password !== confirmPassword) {
      setError("Passwords do not match.");
      return;
    }

    if (!userData.name || !userData.phone || !userData.email) {
      setError("All fields are required.");
      return;
    }

    setLoading(true);
    setError(null);

    try {
      const response = await fetch(`https://tpostapi.mytk.app/api/user/updateByEmail/${email}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          name: userData.name,
          email: userData.email,
          phone: userData.phone,
          password: password || undefined, // Si le mot de passe est vide, on ne l'inclut pas
        }),
      });

      const data = await response.json();

      if (response.ok) {
        setSuccess("User updated successfully!");
        setTimeout(() => navigate("/dashboard"), 2000); // Redirection après succès
      } else {
        setError(data.message || "Failed to update user.");
      }
    } catch (err) {
      console.error("Error updating user:", err);
      setError("An unexpected error occurred.");
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (e) => {
    setUserData({ ...userData, [e.target.name]: e.target.value });
  };

  return (
    <BasicLayout image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Update your information
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          {loading ? (
            <MDTypography variant="h6" color="info">
              Loading user data...
            </MDTypography>
          ) : (
            <MDBox component="form" role="form" onSubmit={handleUpdate}>
              <MDBox mb={2}>
                <MDInput
                  type="text"
                  label="Name"
                  name="name"
                  fullWidth
                  value={userData.name}
                  onChange={handleChange}
                />
              </MDBox>

              <MDBox mb={2}>
                <MDInput
                  type="text"
                  label="Phone"
                  name="phone"
                  fullWidth
                  value={userData.phone}
                  onChange={handleChange}
                />
              </MDBox>

              <MDBox mb={2}>
                <MDInput
                  type="password"
                  label="Password"
                  fullWidth
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </MDBox>

              <MDBox mb={2}>
                <MDInput
                  type="password"
                  label="Confirm password"
                  fullWidth
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />
              </MDBox>

              <MDBox mt={4} mb={1}>
                <MDButton variant="gradient" color="info" fullWidth type="submit">
                  Update
                </MDButton>
              </MDBox>

              {error && (
                <MDBox mt={2} mb={1}>
                  <MDTypography variant="caption" color="error">
                    {error}
                  </MDTypography>
                </MDBox>
              )}

              {success && (
                <MDBox mt={2} mb={1}>
                  <MDTypography variant="caption" color="success">
                    {success}
                  </MDTypography>
                </MDBox>
              )}
            </MDBox>
          )}
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default SignUp;
