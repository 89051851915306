import React, { useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TablePagination from "@mui/material/TablePagination";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import EditIcon from "@mui/icons-material/Edit";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import { getPosts, updatePost } from "layouts/eventpost/indexService";
import DownloadIcon from "@mui/icons-material/Download";
import { getUserWithCompany } from "layouts/user/userService";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { getCompany } from "layouts/compagnies/companyService";
import Modal from "@mui/material/Modal";
import InputLabel from "@mui/material/InputLabel";
import Checkbox from "@mui/material/Checkbox";
import ListItemText from "@mui/material/ListItemText";
import TextField from "@mui/material/TextField";

const columns = [
  { id: "code", label: <b>Code</b> },
  { id: "title", label: <b>Post Title</b> },
  { id: "description", label: <b>Description</b> },
  { id: "state", label: <b>Status</b> },
];

function createData(
  code,
  title,
  description,
  state,
  id,
  compagnyId,
  images,
  startDate,
  endDate,
  url
) {
  return { code, title, description, state, id, compagnyId, images, startDate, endDate, url };
}

export default function StickyHeadTable() {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(7);
  const [statusFilter, setStatusFilter] = useState("");
  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [userCompanies, setUserCompanies] = useState([]);
  const [rows, setRows] = useState([]);
  const [compagnies, setCompagnies] = useState([]); // Liste des compagnies

  const [startDate, setStartDate] = useState(""); // Date de début
  const [endDate, setEndDate] = useState(""); // Date de fin
  const [showPostDetails, setShowPostDetails] = useState(true);

  const getToken = () => {
    return localStorage.getItem("token");
  };

  const [updatedPost, setUpdatedPost] = useState({
    title: "",
    description: "",
    state: "",
    startDate: "",
    endDate: "",
    images: [],
    url: "",
  });

  const userRole = localStorage.getItem("userRole");

  const fetchUserCompanies = async () => {
    const userId = localStorage.getItem("currentUserId");
    if (!userId) {
      console.error("L'utilisateur n'est pas connecté.");
      return [];
    }

    try {
      const response = await getUserWithCompany(userId);
      const companies = Array.isArray(response?.compagnies) ? response.compagnies : [];

      console.log("Compagnies récupérées pour l'utilisateur :", companies); // Log pour vérifier les compagnies
      setUserCompanies(companies);
      return companies;
    } catch (error) {
      console.error("Erreur lors de la récupération des compagnies de l'utilisateur :", error);
      return [];
    }
  };
  const getCompagnyName = async (compagnyId) => {
    console.log("Appel API pour la compagnie avec ID :", compagnyId);

    const token = getToken();
    console.log("Token récupéré :", token);

    const apiUrl = `https://tpostapi.mytk.app/api/compagny/show/${compagnyId}`;
    console.log("URL de l'API :", apiUrl);

    try {
      const response = await fetch(apiUrl, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        console.error("Erreur lors de la récupération de la compagnie", response);
        const errorData = await response.json();
        console.error("Détails de l'erreur:", errorData);
        return null; // Retourner null si la requête échoue
      }

      const data = await response.json();
      console.log("Données de la compagnie :", data);

      if (data && data.compagny && data.compagny.name) {
        return data.compagny.name; // Retourner le nom de la compagnie
      } else {
        console.warn("Nom de la compagnie non trouvé dans la réponse");
        return null; // Retourner null si le nom n'est pas présent
      }
    } catch (error) {
      console.error("Erreur lors de la récupération des informations de la compagnie", error);
      return null;
    }
  };

  const fetchData = async () => {
    try {
      // Récupère les compagnies de l'utilisateur
      const companies = await fetchUserCompanies();
      let companyIds = [];
      if (companies.length > 0) {
        companyIds = companies.map((company) => company.id);
        console.log("IDs des compagnies de l'utilisateur :", companyIds); // Log les IDs des compagnies
      } else {
        console.warn("Aucune compagnie associée à l'utilisateur connecté.");
      }

      // Récupére les posts en fonction des IDs de compagnies et du rôle utilisateur
      const posts = await getPosts(); // Supposons que cette fonction récupère tous les posts
      console.log("Posts récupérés :", posts); // Log pour vérifier les posts récupérés

      if (!Array.isArray(posts)) {
        console.warn("La réponse des posts n'est pas un tableau.");
        return;
      }

      let filteredPosts;
      if (userRole === "ADMIN") {
        filteredPosts = posts.filter((post) => companyIds.includes(post.compagny_id));
      } else {
        filteredPosts = posts;
      }

      // Applique le filtre de statut
      if (statusFilter) {
        filteredPosts = filteredPosts.filter((post) => post.state === statusFilter);
      }

      // Vérification des données avant d'être mises dans le state
      const validatedPosts = filteredPosts.map((post) => {
        return createData(
          post.code || "",
          post.title || "",
          post.description || "",
          post.state || "",
          post.id,
          post.compagny_id,
          post.images || [],
          post.startDate || "",
          post.endDate || "",
          post.url || ""
        );
      });

      console.log("Filtered Posts après le filtrage :", validatedPosts);
      setRows(validatedPosts);
    } catch (error) {
      console.error("Erreur lors de la récupération des posts :", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [statusFilter]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleStatusChange = (event) => {
    setStatusFilter(event.target.value);
    setPage(0);
  };

  const handleRowClick = async (row) => {
    setSelectedRow(row);

    console.log("ID de la compagnie sélectionnée:", row.compagnyId); // Vérifie que l'ID est correct

    const compagnyName = await getCompagnyName(row.compagnyId);

    if (compagnyName) {
      setSelectedRow((prevSelectedRow) => ({
        ...prevSelectedRow,
        compagnyInfo: compagnyName,
      }));
    } else {
      setSelectedRow((prevSelectedRow) => ({
        ...prevSelectedRow,
        compagnyInfo: "Nom de la compagnie non trouvé",
      }));
    }

    setUpdatedPost({
      title: row.title,
      description: row.description,
      state: row.state,
      url: row.url,
      startDate: formatDateForInput(row.startDate),
      endDate: formatDateForInput(row.endDate),
      images: row.images || [],
    });

    setOpen(true);
  };
  console.log(selectedRow);

  const handleClose = () => {
    setOpen(false);
    setSelectedRow(null);
  };

  const handleUpdatePost = async () => {
    try {
      const updatedData = {
        title: updatedPost.title,
        description: updatedPost.description,
        state: updatedPost.state,
        startDate: updatedPost.startDate,
        endDate: updatedPost.endDate,
        compagny_id: selectedRow.compagnyId,
        images: updatedPost.images,
        url: updatedPost.url,
      };

      await updatePost(selectedRow.id, updatedData);
      handleClose();
      await fetchData();
    } catch (error) {
      console.error("Erreur lors de la mise à jour du post :", error);
    }
  };
  const images = Array.isArray(updatedPost.images)
    ? updatedPost.images
    : typeof updatedPost.images === "string"
    ? JSON.parse(updatedPost.images)
    : [];

  const filteredRows = rows.filter((row) => {
    const matchesStatus = statusFilter ? row.state === statusFilter : true;
    const matchesSearch = row.code
      ? row.code.toLowerCase().includes(searchQuery.toLowerCase())
      : false;
    return matchesStatus && matchesSearch;
  });

  const formatDateForInput = (dateString) => {
    if (!dateString) return "";

    const date = new Date(dateString);
    if (isNaN(date.getTime())) {
      console.warn("Invalid date value:", dateString);
      return "";
    }

    return date.toISOString().split("T")[0]; // Format as yyyy-MM-dd if valid
  };

  const [hoveredImageIndex, setHoveredImageIndex] = useState(null);

  const handleImageDownload = (imageUrl) => {
    console.log("Downloading image from URL: ", imageUrl); // Log pour vérifier l'URL
    const link = document.createElement("a");
    link.href = imageUrl;
    link.setAttribute("download", "image.jpg"); // Nom du fichier téléchargé
    link.setAttribute("target", "_blank");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const formatDate = (dateString) => {
    if (!dateString) return ""; // Si la date est vide ou invalide, retourne une chaîne vide.

    const date = new Date(dateString);
    if (isNaN(date.getTime())) {
      console.warn("Date invalide:", dateString);
      return ""; // Retourne une chaîne vide si la date est invalide.
    }

    const day = ("0" + date.getDate()).slice(-2); // Ajoute un zéro devant les jours inférieurs à 10.
    const month = ("0" + (date.getMonth() + 1)).slice(-2); // Les mois commencent à partir de 0, donc on ajoute 1.
    const year = date.getFullYear();

    return `${day}/${month}/${year}`; // Retourne la date formatée sous forme dd/mm/yyyy.
  };
  const truncateText = (text, maxLength) => {
    return text && text.length > maxLength ? text.slice(0, maxLength) + "..." : text;
  };
  // Fonction pour récupérer les compagnies lorsqu'on ouvre le modal
  const fetchCompagnies = async () => {
    try {
      const compagniesData = await getCompany(); // Récupère les compagnies
      console.log("Compagnies récupérées:", compagniesData); // Vérification des données
      setCompagnies(compagniesData); // Met à jour l'état avec les données des compagnies
    } catch (error) {
      console.error("Erreur lors de la récupération des compagnies:", error);
    }
  };

  // Utilisez useEffect pour appeler fetchCompagnies lors de l'ouverture du modal
  useEffect(() => {
    fetchCompagnies(); // Appel de la fonction pour récupérer les compagnies
  }, []); // L'effet se déclenche au montage du composant

  // Fonction pour gérer la sélection d'une compagnies
  const [selectedCompagnie, setSelectedCompagnie] = React.useState("");

  const handleCompagnieChange = (event) => {
    setSelectedCompagnie(event.target.value);
  };

  // Fonction pour ouvrir et fermer le modal
  const toggleModal = () => {
    setOpen(!open);
    if (!open) {
      fetchCompagnies(); // Récupére les compagnies lorsque le modal s'ouvre
    }
  };

  const handleGenerateBonDeLivraison = async () => {
    // Vérifie qu'une compagnie est sélectionnée
    if (!selectedCompagnie) {
      console.error("Aucune compagnie sélectionnée.");
      alert("Please select a company to generate the delivery note.");
      return;
    }

    console.log("Compagnie sélectionnée :", selectedCompagnie);

    const compagnyID = selectedCompagnie; // Utilisation directe
    console.log("La compagnyID est :", compagnyID);

    // Construire l'URL de l'API avec gestion des dates
    const baseUrl = `https://tpostapi.mytk.app/api/bon-de-livraison/word/${compagnyID}`;

    // Construire dynamiquement l'URL en fonction des dates
    let url = baseUrl;
    if (startDate || endDate) {
      // Assurez-vous que les dates sont valides avant de les ajouter à l'URL
      const formattedStartDate = startDate ? new Date(startDate).toISOString().split("T")[0] : "";
      const formattedEndDate = endDate ? new Date(endDate).toISOString().split("T")[0] : "";
      url += `/${formattedStartDate}/${formattedEndDate}`;
    }

    console.log("URL construite :", url);

    try {
      const token = getToken();
      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        const errorData = await response.json();
        console.error("Erreur du serveur :", errorData.error || "Une erreur s'est produite.");
        alert("Erreur lors de la génération : " + (errorData.error || "Veuillez réessayer."));
        return;
      }

      const result = await response.json();
      console.log("Réponse réussie :", result);

      // Gérer la réponse selon le contenu
      if (result.error && result.error === "No articles found for the specified period") {
        alert("No articles found for the specified period.");
      } else {
        alert("Email sent successfully and file deleted.");
      }

      toggleModal(); // Fermer le modal
    } catch (error) {
      console.error("Erreur réseau :", error.message);
      alert("Une erreur s'est produite lors de la connexion au serveur. Veuillez réessayer.");
    }
  };

  // Masquer ou afficher le formulaire de détails en fonction de l'état du modal
  const hidePostDetails = !open;

  return (
    <Paper>
      <div>
        <MDBox
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <FormControl style={{ margin: "5px" }} sx={{ height: "100%" }}>
            <Select value={statusFilter} onChange={handleStatusChange} displayEmpty>
              <MenuItem value="">All</MenuItem>
              <MenuItem value="new">New</MenuItem>
              <MenuItem value="inProgress">In progress</MenuItem>
              <MenuItem value="submitted">Submitted</MenuItem>
              <MenuItem value="published">Published</MenuItem>
              <MenuItem value="refused">Refused</MenuItem>
            </Select>
          </FormControl>

          <MDInput
            label="Search Code"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            sx={{ marginLeft: "10px" }}
          />

          {userRole === "SUPER ADMIN" || userRole === "AUTHOR" ? (
            <Button variant="contained" color="primary" onClick={toggleModal}>
              Generate the Delivery Note
            </Button>
          ) : null}
        </MDBox>

        {/* Modal pour la sélection des compagnies et période */}
        <Modal open={open} onClose={toggleModal}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 400,
              bgcolor: "background.paper",
              padding: 4,
              borderRadius: 2,
            }}
          >
            <h4>Generate the Delivery Note</h4>

            {/* Check-list des compagnies */}
            <FormControl fullWidth sx={{ marginBottom: "20px" }}>
              <InputLabel>Compagnies</InputLabel>
              <Select
                value={selectedCompagnie} // Une seule valeur pour la sélection
                onChange={handleCompagnieChange}
                renderValue={(selected) =>
                  compagnies.find((compagny) => compagny.id === selected)?.name ||
                  "Sélectionnez une compagnie"
                }
              >
                {/* Vérifiez si 'compagnies' est un tableau avant de tenter d'utiliser .map() */}
                {Array.isArray(compagnies) && compagnies.length > 0 ? (
                  compagnies.map((compagny) => (
                    <MenuItem key={compagny.id} value={compagny.id}>
                      <ListItemText primary={compagny.name} />
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem disabled>Aucune compagnie disponible</MenuItem>
                )}
              </Select>
            </FormControl>

            {/* Sélecteur de période */}
            <TextField
              label="Start Date"
              type="date"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
              sx={{ width: "100%", marginBottom: "20px" }}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <TextField
              label="End Date"
              type="date"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
              sx={{ width: "100%", marginBottom: "20px" }}
              InputLabelProps={{
                shrink: true,
              }}
            />

            {/* Bouton pour générer le bon de livraison */}
            <Button variant="contained" color="primary" onClick={handleGenerateBonDeLivraison}>
              Generate
            </Button>
          </Box>
        </Modal>
      </div>

      <TableContainer sx={{ minHeight: "50vh" }}>
        <Table>
          <TableRow>
            {columns.map((column) => (
              <TableCell key={column.id} align="left">
                {column.label}
              </TableCell>
            ))}
            {userRole === "AUTHOR" && <TableCell align="left">Actions</TableCell>}
          </TableRow>

          <TableBody style={{ cursor: "pointer" }}>
            {filteredRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
              <TableRow
                hover
                role="checkbox"
                tabIndex={-1}
                key={row.code}
                onClick={() => handleRowClick(row)}
              >
                {columns.map((column) => (
                  <TableCell key={column.id} align="left">
                    {column.id === "description" || column.id === "title"
                      ? truncateText(row[column.id], 25)
                      : row[column.id]}
                  </TableCell>
                ))}
                {userRole === "AUTHOR" && (
                  <TableCell align="left">
                    <IconButton onClick={() => handleRowClick(row)}>
                      <EditIcon />
                    </IconButton>
                  </TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[7, 9, 10]}
        component="div"
        count={filteredRows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

      {/* Détails de l'enregistrement */}
      {!hidePostDetails && selectedRow && (
        <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
          <DialogTitle sx={{ fontWeight: "bold", fontSize: "1.5rem", pb: 0 }}>
            Post Details
          </DialogTitle>
          <DialogContent dividers sx={{ pt: 1, display: "flex", flexDirection: "column", gap: 2 }}>
            {selectedRow && (
              <>
                <Typography
                  variant="h6"
                  color="secondary"
                  sx={{ marginBottom: 1, fontWeight: "bold" }}
                >
                  Title: {selectedRow.title}
                </Typography>

                <Typography
                  variant="h6"
                  color="secondary"
                  sx={{ marginBottom: 1, fontWeight: "bold" }}
                >
                  Code: {selectedRow.code}
                </Typography>

                <Typography
                  variant="h6"
                  color="secondary"
                  sx={{ marginBottom: 2, lineHeight: 1.6 }}
                  paragraph
                >
                  Description: {selectedRow.description}
                </Typography>

                <Typography
                  variant="h6"
                  color="secondary"
                  sx={{ marginBottom: 1, fontWeight: "bold" }}
                >
                  Start Date: {formatDate(selectedRow.startDate)}
                </Typography>
                <Typography
                  variant="h6"
                  color="secondary"
                  sx={{ marginBottom: 1, fontWeight: "bold" }}
                >
                  End Date: {formatDate(selectedRow.endDate)}
                </Typography>
                <Typography
                  variant="h6"
                  color="secondary"
                  sx={{ marginBottom: 1, fontWeight: "bold" }}
                >
                  Company: {selectedRow?.compagnyInfo || "Nom de la compagnie non disponible"}
                </Typography>
                {/* Affiche les images si elles existent */}
                {updatedPost.images.length > 0 && (
                  <div>
                    <p>Images:</p>
                    {images.map((image, index) => (
                      <div
                        key={index}
                        onMouseEnter={() => setHoveredImageIndex(index)}
                        onMouseLeave={() => setHoveredImageIndex(null)}
                        style={{ position: "relative", display: "inline-block" }}
                      >
                        <img
                          src={`https://tpostapi.mytk.app${image}`}
                          alt={`Image ${index + 1}`}
                          width="100"
                          style={{ cursor: "pointer" }}
                        />
                        {hoveredImageIndex === index && (
                          <IconButton
                            style={{
                              position: "absolute",
                              top: 0,
                              right: 0,
                              color: "white",
                              backgroundColor: "rgba(0, 0, 0, 0.5)",
                            }}
                            onClick={() => handleImageDownload(`https://tpostapi.mytk.app${image}`)}
                          >
                            <DownloadIcon />
                          </IconButton>
                        )}
                      </div>
                    ))}
                  </div>
                )}
                <Typography
                  variant="h6"
                  color="secondary"
                  sx={{ marginBottom: 1, fontWeight: "bold" }}
                >
                  Status: {selectedRow.state}
                </Typography>
                {userRole === "AUTHOR" && (
                  <>
                    <MDInput
                      label="Title"
                      value={updatedPost.title}
                      onChange={(e) => setUpdatedPost({ ...updatedPost, title: e.target.value })}
                      style={{ display: "none" }}
                    />
                    <MDInput
                      label="Description"
                      value={updatedPost.description}
                      onChange={(e) =>
                        setUpdatedPost({ ...updatedPost, description: e.target.value })
                      }
                      style={{ display: "none" }}
                    />
                    <FormControl fullWidth style={{ margin: "5px 0" }}>
                      <Select
                        label="Status"
                        value={updatedPost.state}
                        onChange={(e) => setUpdatedPost({ ...updatedPost, state: e.target.value })}
                      >
                        <MenuItem value="new">New</MenuItem>
                        <MenuItem value="inProgress">In Progress</MenuItem>
                        <MenuItem value="submitted">Submitted</MenuItem>
                        <MenuItem value="published">Published</MenuItem>
                        <MenuItem value="refused">Refused</MenuItem>
                      </Select>
                    </FormControl>

                    {/* Input URL du post, visible uniquement si le statut est 'submitted' */}
                    {updatedPost.state === "submitted" && (
                      <MDInput
                        label="Post URL"
                        value={updatedPost.url}
                        onChange={(e) => setUpdatedPost({ ...updatedPost, url: e.target.value })}
                      />
                    )}

                    <MDInput
                      label="Start Date"
                      type="date"
                      value={updatedPost.startDate}
                      onChange={(e) =>
                        setUpdatedPost({ ...updatedPost, startDate: e.target.value })
                      }
                      style={{ display: "none" }}
                    />
                    <MDInput
                      label="End Date"
                      type="date"
                      value={updatedPost.endDate}
                      onChange={(e) => setUpdatedPost({ ...updatedPost, endDate: e.target.value })}
                      style={{ display: "none" }}
                    />
                  </>
                )}
              </>
            )}
          </DialogContent>

          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>

            {/* Vérifier que selectedRow existe et que son état est "submitted" */}
            {userRole !== "AUTHOR" && selectedRow && selectedRow.state === "submitted" && (
              <Button
                onClick={() => {
                  if (updatedPost.url) {
                    window.location.href = updatedPost.url; // Redirige vers l'URL
                  } else {
                    alert("Aucune URL fournie"); // Affiche un message si l'URL est vide
                  }
                }}
              >
                Read and approved
              </Button>
            )}

            {userRole === "AUTHOR" && <Button onClick={handleUpdatePost}>Update</Button>}
          </DialogActions>
        </Dialog>
      )}
    </Paper>
  );
}
