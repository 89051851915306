import React, { useEffect, useState } from "react";
import PageManagementService from "./PageService";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Card,
  CardContent,
  Grid,
  TablePagination,
  Snackbar,
  Alert,
} from "@mui/material";
import { Edit, Delete } from "@mui/icons-material";
import { styled } from "@mui/material/styles";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";

const CenteredCard = styled(Card)(({ theme }) => ({
  maxWidth: 1500,
  margin: "auto",
  marginTop: theme.spacing(4),
  padding: theme.spacing(2),
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  flex: 1,
  textAlign: "center",
}));

const Page = () => {
  const [roles, setRoles] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [newPermission, setNewPermission] = useState({
    id: "",
    page_name: "",
  });
  const [openDialog, setOpenDialog] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("success");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    fetchRoles();
  }, []);

  const fetchRoles = async () => {
    try {
      const response = await PageManagementService.getAllRolePermissions();
      setRoles(response);
    } catch (error) {
      console.error("Failed to fetch roles:", error);
    }
  };

  const handleOpenDialog = () => {
    setNewPermission({ id: "", page_name: "" });
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setNewPermission((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSavePermission = async () => {
    setIsSubmitting(true);
    try {
      if (newPermission.id) {
        // Mise à jour
        await PageManagementService.updatePermission(newPermission.id, {
          page_name: newPermission.page_name,
        });
        setAlertMessage("Page updated successfully.");
      } else {
        // Création
        const page = await PageManagementService.createPermission({
          page_name: newPermission.page_name,
        });
        setRoles((prevRoles) => [...prevRoles, page.page]);
        setAlertMessage("Permission created successfully.");
      }
      setAlertSeverity("success");
      handleCloseDialog();
      fetchRoles(); // Mise à jour complète
    } catch (error) {
      console.error("Failed to save permission:", error);
      setAlertMessage("Failed to save permission.");
      setAlertSeverity("error");
    } finally {
      setOpenSnackbar(true);
      setIsSubmitting(false);
    }
  };

  const handleDeletePermission = async (permissionId) => {
    try {
      await PageManagementService.deleteRole(permissionId);
      setAlertMessage("Page deleted successfully.");
      setAlertSeverity("success");
      setRoles((prevRoles) => prevRoles.filter((role) => role.id !== permissionId));
    } catch (error) {
      console.error("Failed to delete permission:", error);
      setAlertMessage("Failed to delete permission.");
      setAlertSeverity("error");
    } finally {
      setOpenSnackbar(true);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const paginatedData = roles.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={2} sx={{ minHeight: "81vh" }}>
        <Snackbar
          open={openSnackbar}
          autoHideDuration={4000}
          onClose={() => setOpenSnackbar(false)}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert
            onClose={() => setOpenSnackbar(false)}
            severity={alertSeverity}
            sx={{ width: "100%" }}
          >
            {alertMessage}
          </Alert>
        </Snackbar>
        <MDBox>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <CenteredCard>
                <CardContent>
                  <Button
                    onClick={handleOpenDialog}
                    sx={{
                      backgroundColor: "#87CEFA",
                      color: "#FFFFFF",
                      marginTop: "10px",
                      "&:hover": {
                        backgroundColor: "#00BFFF",
                      },
                    }}
                  >
                    ADD Page
                  </Button>

                  <TableContainer
                    component={Paper}
                    style={{ marginTop: 10, width: "95%", marginLeft: "auto", marginRight: "auto" }}
                  >
                    <Table>
                      <TableRow>
                        <StyledTableCell>Page Name</StyledTableCell>
                        <StyledTableCell>Actions</StyledTableCell>
                      </TableRow>
                      <TableBody>
                        {paginatedData.map((permission) => (
                          <TableRow key={permission.id}>
                            <StyledTableCell>{permission.page_name}</StyledTableCell>
                            <StyledTableCell>
                              <Button
                                onClick={() => {
                                  setNewPermission(permission);
                                  setOpenDialog(true);
                                }}
                              >
                                Edit
                              </Button>
                              <Button
                                color="secondary"
                                onClick={() => handleDeletePermission(permission.id)}
                              >
                                Delete
                              </Button>
                            </StyledTableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={roles.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </CardContent>
              </CenteredCard>

              <Dialog open={openDialog} onClose={handleCloseDialog}>
                <DialogTitle>{newPermission.id ? "Edit Page" : "Add Page"}</DialogTitle>
                <DialogContent>
                  <TextField
                    name="page_name"
                    label="Page Name"
                    value={newPermission.page_name}
                    onChange={handleInputChange}
                    fullWidth
                    margin="normal"
                  />
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleCloseDialog} color="primary">
                    Cancel
                  </Button>
                  <Button onClick={handleSavePermission} color="primary" disabled={isSubmitting}>
                    {isSubmitting ? "Saving..." : "Save"}
                  </Button>
                </DialogActions>
              </Dialog>
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
};

export default Page;
