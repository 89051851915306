import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Card,
  CardContent,
  Grid,
  Box,
  TablePagination,
  FormControlLabel,
  Checkbox,
  Snackbar,
  Alert,
} from "@mui/material";
import { Edit, Delete } from "@mui/icons-material";
import { styled } from "@mui/material/styles";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import { getAllRoles, createRole, updateRole, deleteRole } from "./roleService";

const CenteredCard = styled(Card)(({ theme }) => ({
  maxWidth: 1500,
  margin: "auto",
  marginTop: theme.spacing(4),
  padding: theme.spacing(2),
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  flex: 1,
  textAlign: "center",
}));

const Role = () => {
  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [currentItem, setCurrentItem] = useState({
    id: null,
    name: "",
    description: "",
    permissions: { read: false, write: false, update: false, delete: false },
  });
  const [itemToDelete, setItemToDelete] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  // Notification State
  const [alertMessage, setAlertMessage] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("success");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const showAlert = (message, severity = "success") => {
    setAlertMessage(message);
    setAlertSeverity(severity);
    setOpenSnackbar(true);
  };

  // Fonction pour récupérer les rôles
  const fetchRoles = async () => {
    try {
      const roles = await getAllRoles();
      setData(roles);
    } catch (error) {
      console.error("Failed to fetch roles:", error);
    }
  };

  useEffect(() => {
    fetchRoles();
  }, []);

  // Fonction pour ouvrir le formulaire d'ajout ou d'édition
  const handleOpen = (item) => {
    setCurrentItem(
      item || {
        id: null,
        name: "",
        description: "",
        permissions: { read: false, write: false, update: false, delete: false },
      }
    );
    setOpen(true);
  };

  const handleClose = () => {
    setCurrentItem({
      id: null,
      name: "",
      description: "",
      permissions: { read: false, write: false, update: false, delete: false },
    });
    setOpen(false);
  };

  const handlePermissionChange = (permission) => {
    setCurrentItem((prev) => ({
      ...prev,
      permissions: {
        ...prev.permissions,
        [permission]: !prev.permissions[permission],
      },
    }));
  };

  const handleSave = async () => {
    if (isSubmitting) return; // Empêche une soumission multiple
    setIsSubmitting(true); // Désactive le bouton
    try {
      const roleData = {
        ...currentItem,
      };

      if (!roleData.name || !roleData.description) {
        showAlert("Name and description are required.", "error");
        setIsSubmitting(false); // Réactive le bouton
        return;
      }

      if (currentItem.id) {
        await updateRole(currentItem.id, currentItem);
        setData(data.map((role) => (role.id === currentItem.id ? currentItem : role)));
        showAlert("Role updated successfully.");
      } else {
        const newRole = await createRole(currentItem);
        setData([...data, newRole]);
        showAlert("Role created successfully.");
      }

      fetchRoles();
      handleClose();
    } catch (error) {
      console.error("Failed to save role:", error);
      showAlert("Failed to save role.", "error");
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleDelete = async (id) => {
    try {
      await deleteRole(id);
      setData(data.filter((item) => item.id !== id));
      // Recharger les rôles après suppression
      fetchRoles();
      handleCloseConfirm();
      showAlert("Role deleted successfully.");
    } catch (error) {
      console.error("Failed to delete role:", error);
    }
  };

  const handleOpenConfirm = (item) => {
    setItemToDelete(item);
    setOpenConfirm(true);
  };

  const handleCloseConfirm = () => {
    setItemToDelete(null);
    setOpenConfirm(false);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const paginatedData = data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={2} sx={{ minHeight: "81vh" }}>
        <Snackbar
          open={openSnackbar}
          autoHideDuration={4000}
          onClose={() => setOpenSnackbar(false)}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert
            onClose={() => setOpenSnackbar(false)}
            severity={alertSeverity}
            sx={{ width: "100%" }}
          >
            {alertMessage}
          </Alert>
        </Snackbar>
        <MDBox>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <CenteredCard>
                <CardContent>
                  <Button
                    onClick={() => handleOpen()}
                    sx={{
                      backgroundColor: "#87CEFA",
                      color: "#FFFFFF",
                      marginLeft: "12px",
                      marginTop: "-10px",
                      "&:hover": {},
                      "& .MuiButton-label": {
                        color: "#FFFFFF",
                      },
                    }}
                  >
                    Add Role
                  </Button>
                  <TableContainer
                    component={Paper}
                    style={{ marginTop: 10, width: "95%", marginLeft: "auto", marginRight: "auto" }}
                  >
                    <Table>
                      <TableRow>
                        <TableCell style={{ width: "30%", fontWeight: "bold" }}>Role</TableCell>
                        <TableCell style={{ width: "40%", fontWeight: "bold" }}>
                          Description
                        </TableCell>
                        <StyledTableCell style={{ width: "30%", fontWeight: "bold" }}>
                          Actions
                        </StyledTableCell>
                      </TableRow>
                      <TableBody>
                        {paginatedData.map((item) => (
                          <TableRow key={item.id}>
                            <TableCell>{item.name}</TableCell>
                            <TableCell>{item.description}</TableCell>
                            <StyledTableCell>
                              <Box sx={{ display: "flex", justifyContent: "center", gap: 1 }}>
                                <Button onClick={() => handleOpen(item)}>
                                  <Edit />
                                </Button>
                                <Button onClick={() => handleOpenConfirm(item)}>
                                  <Delete sx={{ color: "red" }} />
                                </Button>
                              </Box>
                            </StyledTableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                    <TablePagination
                      rowsPerPageOptions={[7, 10, 25]}
                      component="div"
                      count={data.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      style={{ padding: "16px" }}
                    />
                  </TableContainer>
                </CardContent>
              </CenteredCard>

              <Dialog open={open} onClose={handleClose}>
                <DialogTitle>{currentItem.id ? "Edit Role" : "Add Role"}</DialogTitle>
                <DialogContent>
                  <TextField
                    margin="dense"
                    label="Role"
                    fullWidth
                    value={currentItem.name}
                    onChange={(e) => setCurrentItem({ ...currentItem, name: e.target.value })}
                  />
                  <TextField
                    margin="dense"
                    label="Description"
                    fullWidth
                    multiline
                    rows={4}
                    value={currentItem.description}
                    onChange={(e) =>
                      setCurrentItem({ ...currentItem, description: e.target.value })
                    }
                  />
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleClose} color="primary">
                    Cancel
                  </Button>

                  <Button onClick={handleSave} color="primary" disabled={isSubmitting}>
                    {isSubmitting ? "Saving..." : "Save"}
                  </Button>
                </DialogActions>
              </Dialog>

              <Dialog open={openConfirm} onClose={handleCloseConfirm}>
                <DialogTitle>Confirm Deletion</DialogTitle>
                <DialogContent>Are you sure you want to delete this role?</DialogContent>
                <DialogActions>
                  <Button onClick={handleCloseConfirm} color="primary">
                    Cancel
                  </Button>
                  <Button onClick={() => handleDelete(itemToDelete.id)} color="primary">
                    Delete
                  </Button>
                </DialogActions>
              </Dialog>
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
};

export default Role;
