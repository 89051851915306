import React, { useRef, useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Card,
  CardContent,
  Grid,
  Box,
  TablePagination,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Snackbar,
  Alert,
} from "@mui/material";
import { Edit, Delete, QueuePlayNext } from "@mui/icons-material";
import { styled } from "@mui/material/styles";

import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";

import { getPosts, createPost, updatePost, deletePost } from "./indexService";
import { getUserWithCompany } from "../user/userService";
import IconButton from "@mui/material/IconButton"; // Pour le bouton d'icône
import DeleteIcon from "@mui/icons-material/Delete"; // Pour l'icône de suppression

// Styled components
const CenteredCard = styled(Card)(({ theme }) => ({
  maxWidth: 1500,
  margin: "auto",
  marginTop: theme.spacing(4),
  padding: theme.spacing(2),
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  flex: 1,
  textAlign: "center",
}));

const FileInput = styled("input")({
  display: "none",
});

const ImagePreview = styled("img")({
  maxWidth: "100px",
  maxHeight: "100px",
  objectFit: "cover",
  margin: "5px",
});

const TableComponent = () => {
  const [data, setData] = useState([]);
  const fileInputRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [currentItem, setCurrentItem] = useState({
    id: null,
    title: "",
    description: "",
    persons: "",
    lieu: "",
    startDate: "",
    endDate: "",
    images: [],
    compagny_id: "",
    relatedToEvent: false,
    state: "",
    url: "",
  });
  const [itemToDelete, setItemToDelete] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(7);
  const [imagePreviews, setImagePreviews] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [error, setError] = useState("");
  const getToken = () => {
    return localStorage.getItem("token");
  };
  const [userCompanies, setUserCompanies] = useState([]);
  // Notification State
  const [alertMessage, setAlertMessage] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("success");
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const showAlert = (message, severity = "success") => {
    setAlertMessage(message);
    setAlertSeverity(severity);
    setOpenSnackbar(true);
  };

  const fetchUserCompanies = async () => {
    const userId = localStorage.getItem("currentUserId");
    if (!userId) {
      console.error("L'utilisateur n'est pas connecté.");
      return [];
    }

    try {
      const response = await getUserWithCompany(userId);
      const companies = Array.isArray(response?.compagnies) ? response.compagnies : [];

      console.log("Compagnies récupérées pour l'utilisateur :", companies); // Log pour vérifier les compagnies
      setUserCompanies(companies);
      return companies;
    } catch (error) {
      console.error("Erreur lors de la récupération des compagnies de l'utilisateur :", error);
      return [];
    }
  };

  const fetchPosts = async (companyIds) => {
    try {
      const posts = await getPosts();
      console.log("Posts récupérés :", posts); // Log pour vérifier les posts récupérés

      if (!Array.isArray(posts)) {
        console.warn("La réponse des posts n'est pas un tableau.");
        return;
      }

      // Filtre les posts en utilisant `compagny_id` comme clé
      const filteredPosts = posts.filter((post) => companyIds.includes(post.compagny_id));

      setData(filteredPosts);
    } catch (error) {
      console.error("Échec de la récupération des posts :", error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const companies = await fetchUserCompanies();
      if (companies.length > 0) {
        const companyIds = companies.map((company) => company.id);
        console.log("IDs des compagnies de l'utilisateur :", companyIds); // Log les IDs des compagnies
        await fetchPosts(companyIds);
      } else {
        console.warn("Aucune compagnie associée à l'utilisateur connecté.");
      }
    };

    fetchData(); // Appel initial pour récupérer les compagnies et les posts
    const interval = setInterval(fetchData, 5000); // Récupère les données toutes les 5 secondes

    return () => clearInterval(interval); // Nettoyage de l'intervalle au démontage du composant
  }, []);

  const handleOpen = (item) => {
    setCurrentItem(
      item || {
        id: null,
        title: "",
        description: "",
        persons: "",
        lieu: "",
        startDate: "",
        endDate: "",
        images: [],
        compagny_id: "",
        relatedToEvent: false,
        state: "",
        url: "",
      }
    );
    setImagePreviews(item?.images || []);
    setOpen(true);
  };

  const handleClose = () => {
    setCurrentItem({
      id: null,
      title: "",
      description: "",
      persons: "",
      lieu: "",
      startDate: "",
      endDate: "",
      images: [],
      compagny_id: "",
      relatedToEvent: false,
      state: "",
      url: "",
    });
    setImagePreviews([]);
    setOpen(false);
  };
  const validateImage = (image) => {
    const validTypes = ["image/jpeg", "image/png", "image/jpg", "image/gif"];
    const maxSize = 2 * 1024 * 1024; // 2 Mo

    if (!validTypes.includes(image.type)) {
      return { valid: false, message: "Type de fichier non valide" };
    }

    if (image.size > maxSize) {
      return { valid: false, message: "Le fichier est trop volumineux" };
    }

    return { valid: true };
  };

  const uploadImage = async (compagnyId, picture) => {
    const token = getToken();
    console.log("Token:", token);
    const validation = validateImage(picture);
    console.log("Image validation:", validation);

    if (!validation.valid) {
      console.error("Validation failed:", validation.message);
      throw new Error(validation.message);
    }

    const formData = new FormData();
    formData.append("picture", picture); // Ajoute l'image au formData
    console.log("FormData content:", formData.get("picture")); // Affiche l'image ajoutée au FormData

    const response = await fetch(`https://tpostapi.mytk.app/api/post/upload/${compagnyId}`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: formData,
    });

    console.log("Response status:", response.status); // Affiche le statut de la réponse
    if (!response.ok) {
      console.error("Upload error:", await response.text()); // Affiche le message d'erreur si l'upload échoue
      throw new Error("Erreur lors de l'upload");
    }

    const result = await response.json();
    console.log("Upload successful:", result); // Affiche le résultat de l'upload
    return result.image_url;
  };

  const handleImageUpload = async (e) => {
    const files = Array.from(e.target.files);

    if (files.length === 0) {
      console.error("Veuillez sélectionner au moins un fichier.");
      return;
    }

    const validFiles = files.filter((file) => file.type.startsWith("image/"));
    if (validFiles.length === 0) {
      console.error("Aucun fichier sélectionné n'est une image.");
      return;
    }

    const newImageUrls = validFiles.map((file) => URL.createObjectURL(file));
    setImagePreviews((prev) => [...prev, ...newImageUrls]);

    const compagny_id = currentItem.compagny_id;

    for (const image of validFiles) {
      try {
        const uploadedImageUrl = await uploadImage(compagny_id, image);
        console.log("Image uploadée avec succès:", uploadedImageUrl);

        // Enregistrement de l'URL de l'image dans le currentItem
        setCurrentItem((prev) => ({
          ...prev,
          images: [...prev.images, uploadedImageUrl], // Ajout de l'URL à la liste des images
        }));
      } catch (error) {
        console.error("Erreur lors de l'upload de l'image:", error);
      }
    }
  };

  // gestion de la suppression d'images
  const handleRemoveImagee = (url) => {
    setImagePreviews((prev) => prev.filter((preview) => preview !== url));

    setCurrentItem((prev) => ({
      ...prev,
      images: prev.images.filter((image) => URL.createObjectURL(image) !== url),
    }));
  };
  const images = Array.isArray(currentItem.images)
    ? currentItem.images
    : typeof currentItem.images === "string"
    ? (() => {
        try {
          return JSON.parse(currentItem.images); // Parse si c'est une chaîne JSON
        } catch (error) {
          console.error("Images is not a valid JSON string:", currentItem.images);
          return [];
        }
      })()
    : []; // Sinon, utilisez un tableau vide

  const handleSave = async () => {
    if (isSubmitting) return; // Empêche une soumission multiple
    setIsSubmitting(true); // Désactive le bouton

    try {
      if (
        !currentItem.title ||
        !currentItem.description ||
        !currentItem.compagny_id ||
        !currentItem.images
      ) {
        setError("Title, description, images and company are required."); // Validation des champs
        setIsSubmitting(false); // Réactive le bouton
        return;
      }

      const postData = {
        title: currentItem.title,
        description: currentItem.description,
        compagny_id: currentItem.compagny_id,
        startDate: currentItem.startDate,
        endDate: currentItem.endDate,
        persons: currentItem.persons,
        lieu: currentItem.lieu,
        relatedToEvent: currentItem.relatedToEvent,
        images: currentItem.images,
        state: currentItem.state,
        url: currentItem.url,
      };

      console.log("Données du post:", postData);

      if (currentItem.id) {
        await updatePost(currentItem.id, postData);
        setData(data.map((post) => (post.id === currentItem.id ? { ...post, ...postData } : post)));
        showAlert("Post updated successfully.");
      } else {
        const newPost = await createPost(postData);
        setData([...data, newPost]);
        showAlert("Post created successfully.");
      }

      handleClose();
    } catch (error) {
      console.error("Failed to save post:", error);
    } finally {
      setIsSubmitting(false); // Réactive le bouton
    }
  };

  const handleDelete = async (id) => {
    try {
      await deletePost(id);
      setData(data.filter((item) => item.id !== id));
      handleCloseConfirm();
      showAlert("Post deleted successfully.");
    } catch (error) {
      console.error("Failed to delete post:", error);
    }
  };

  const handleOpenConfirm = (item) => {
    setItemToDelete(item);
    setOpenConfirm(true);
  };

  const handleCloseConfirm = () => {
    setItemToDelete(null);
    setOpenConfirm(false);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const formatDate = (dateStr) => {
    if (!dateStr) return "";

    const date = new Date(dateStr); // Crée un objet Date à partir de la chaîne
    const day = String(date.getDate()).padStart(2, "0"); // Formate le jour
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Formate le mois
    const year = date.getFullYear(); // Récupère l'année

    return `${day}/${month}/${year}`; // Retourne la date au format jj/mm/aaaa
  };

  const paginatedData = data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
  const truncateText = (text, maxLength) => {
    return text && text.length > maxLength ? text.slice(0, maxLength) + "..." : text;
  };

  // Fonction pour supprimer l'image du serveur
  const deleteImage = async (compagnyId, imageUrl) => {
    const token = getToken();
    console.log("Token:", token);

    // Si l'URL de l'image est vide ou incorrecte
    if (!imageUrl || imageUrl.trim() === "") {
      console.error("L'URL de l'image est manquante ou invalide.");
      return;
    }

    // Ajouter le domaine complet si nécessaire
    const fullImageUrl = `https://tpostapi.mytk.app${imageUrl.trim()}`;
    console.log("URL complète de l'image:", fullImageUrl);

    try {
      const response = await fetch("https://tpostapi.mytk.app/api/post/del_img", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          compagnyId: compagnyId,
          picture: fullImageUrl, // Utilisation de l'URL complète ici
        }),
      });

      const responseText = await response.text();
      console.log("Réponse du serveur :", responseText);

      if (!response.ok) {
        console.error("Erreur lors de la suppression de l'image:", responseText);
        throw new Error("Erreur lors de la suppression de l'image");
      }

      const result = JSON.parse(responseText);
      console.log("Image supprimée avec succès:", result);
      return result;
    } catch (error) {
      console.error("Erreur lors de la suppression:", error);
      throw error;
    }
  };

  // Fonction pour gérer la suppression de l'image du serveur
  const handleRemoveImage = async (imageUrl) => {
    // Custom confirmation message in English
    const confirmation = window.confirm(
      "Are you sure you want to delete this image? This action cannot be undone."
    );

    if (!confirmation) {
      console.log("Deletion cancelled.");
      return; // If the user cancels, don't continue with the action
    }

    try {
      // Proceed with the delete action after confirmation
      await deleteImage(imageUrl); // You can call deleteImage function for actual deletion

      // Update local states (if necessary) after successful deletion
      setImagePreviews((prev) => prev.filter((preview) => preview !== imageUrl));
      setCurrentItem((prev) => ({
        ...prev,
        images: prev.images.filter((image) => image !== imageUrl),
      }));

      console.log("Image successfully deleted");
    } catch (error) {
      console.error("Error deleting image:", error);
    }
  };

  return (
    <DashboardLayout
      sx={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <DashboardNavbar />
      <MDBox
        sx={{
          flex: 1,
          py: 2,
          minHeight: "80vh",
        }}
      >
        {/* Snackbar for Notifications */}
        <Snackbar
          open={openSnackbar}
          autoHideDuration={4000}
          onClose={() => setOpenSnackbar(false)}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert
            onClose={() => setOpenSnackbar(false)}
            severity={alertSeverity}
            sx={{ width: "100%" }}
          >
            {alertMessage}
          </Alert>
        </Snackbar>

        <MDBox>
          <Grid container spacing={5}>
            <Grid item xs={12}>
              <CenteredCard>
                <CardContent>
                  <Button
                    onClick={() => handleOpen()}
                    sx={{
                      backgroundColor: "#87CEFA",
                      color: "#FFFFFF",
                      marginLeft: "12px",
                      marginTop: "-10px",
                      "&:hover": {},
                      "& .MuiButton-label": {
                        color: "#FFFFFF",
                      },
                    }}
                  >
                    Add Post
                  </Button>
                  <TableContainer
                    component={Paper}
                    sx={{ marginTop: 2, width: "96%", margin: "auto" }}
                  >
                    <Table>
                      <TableRow>
                        <StyledTableCell style={{ width: "25%", fontWeight: "bold" }}>
                          Title
                        </StyledTableCell>
                        <StyledTableCell style={{ width: "20%", fontWeight: "bold" }}>
                          Description
                        </StyledTableCell>
                        <StyledTableCell style={{ width: "15%", fontWeight: "bold" }}>
                          Start Date
                        </StyledTableCell>
                        <StyledTableCell style={{ width: "15%", fontWeight: "bold" }}>
                          End Date
                        </StyledTableCell>

                        <StyledTableCell style={{ width: "25%", fontWeight: "bold" }}>
                          Actions
                        </StyledTableCell>
                      </TableRow>

                      <TableBody>
                        {paginatedData.map((item) => (
                          <TableRow key={item.id}>
                            <StyledTableCell>{truncateText(item.title, 15)}</StyledTableCell>
                            <StyledTableCell>{truncateText(item.description, 15)}</StyledTableCell>
                            <StyledTableCell>{formatDate(item.startDate)}</StyledTableCell>{" "}
                            {/* Formatage de startDate */}
                            <StyledTableCell>{formatDate(item.endDate)}</StyledTableCell>{" "}
                            {/* Formatage de endDate */}
                            {/* Affiche les icônes seulement si l'état est "new", sinon affiche le statut du post */}
                            <StyledTableCell>
                              {item.state === "new" ? (
                                <Box sx={{ display: "flex", justifyContent: "center", gap: 1 }}>
                                  <Button onClick={() => handleOpen(item)}>
                                    <Edit />
                                  </Button>
                                  <Button onClick={() => handleOpenConfirm(item)}>
                                    <Delete sx={{ color: "red" }} />
                                  </Button>
                                </Box>
                              ) : (
                                <span>{item.state}</span>
                              )}
                            </StyledTableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>

                    <TablePagination
                      rowsPerPageOptions={[7, 10, 25]}
                      component="div"
                      count={data.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      style={{ padding: "16px" }}
                    />
                  </TableContainer>
                </CardContent>
              </CenteredCard>

              <Dialog open={open} onClose={handleClose}>
                <DialogTitle>{currentItem.id ? "Edit Post" : "Add Post"}</DialogTitle>
                <DialogContent>
                  <FormControl fullWidth margin="dense">
                    <InputLabel>Related to Event</InputLabel>
                    <Select
                      value={currentItem.relatedToEvent ? "true" : "false"}
                      onChange={(event) =>
                        setCurrentItem((prev) => ({
                          ...prev,
                          relatedToEvent: event.target.value === "true",
                        }))
                      }
                      label="Related to Event"
                      sx={{ height: "43px" }}
                    >
                      <MenuItem value="false">No</MenuItem>
                      <MenuItem value="true">Yes</MenuItem>
                    </Select>
                  </FormControl>

                  <FormControl fullWidth margin="dense">
                    <InputLabel>Company</InputLabel>
                    <Select
                      value={currentItem.compagny_id || ""}
                      onChange={(e) =>
                        setCurrentItem({ ...currentItem, compagny_id: e.target.value })
                      }
                      sx={{ height: "43px" }}
                    >
                      {userCompanies && Array.isArray(userCompanies) && userCompanies.length > 0 ? (
                        userCompanies.map((company) => (
                          <MenuItem key={company.id} value={company.id}>
                            {company.name}
                          </MenuItem>
                        ))
                      ) : (
                        <MenuItem disabled>Une entreprise est disponible</MenuItem>
                      )}
                    </Select>
                  </FormControl>

                  <TextField
                    margin="dense"
                    label="Title"
                    fullWidth
                    value={currentItem.title}
                    onChange={(e) => setCurrentItem({ ...currentItem, title: e.target.value })}
                  />
                  <TextField
                    margin="dense"
                    label="Description"
                    fullWidth
                    multiline
                    rows={4}
                    value={currentItem.description}
                    onChange={(e) =>
                      setCurrentItem({ ...currentItem, description: e.target.value })
                    }
                  />

                  <TextField
                    margin="dense"
                    label="Url"
                    fullWidth
                    multiline
                    rows={4}
                    value={currentItem.url}
                    onChange={(e) => setCurrentItem({ ...currentItem, url: e.target.value })}
                    style={{ display: "none" }}
                  />

                  {currentItem.relatedToEvent && (
                    <>
                      <TextField
                        margin="dense"
                        label="Start Date"
                        type="date"
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        value={currentItem.startDate ? currentItem.startDate.substring(0, 10) : ""}
                        onChange={(e) =>
                          setCurrentItem({ ...currentItem, startDate: e.target.value })
                        }
                      />
                      <TextField
                        margin="dense"
                        label="End Date"
                        type="date"
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        value={currentItem.endDate ? currentItem.endDate.substring(0, 10) : ""}
                        onChange={(e) =>
                          setCurrentItem({ ...currentItem, endDate: e.target.value })
                        }
                      />
                      <TextField
                        margin="dense"
                        label="People met"
                        fullWidth
                        value={currentItem.persons}
                        onChange={(e) =>
                          setCurrentItem({ ...currentItem, persons: e.target.value })
                        }
                      />
                      <TextField
                        margin="dense"
                        label="Place"
                        fullWidth
                        value={currentItem.lieu}
                        onChange={(e) => setCurrentItem({ ...currentItem, lieu: e.target.value })}
                      />
                    </>
                  )}
                  <Box sx={{ marginTop: 2 }}>
                    <InputLabel></InputLabel>
                    <FileInput
                      type="file"
                      accept="image/*"
                      multiple
                      ref={fileInputRef}
                      onChange={handleImageUpload}
                    />
                    <TextField
                      margin="dense"
                      label="État"
                      fullWidth
                      value={currentItem.state}
                      onChange={(e) => setCurrentItem({ ...currentItem, state: e.target.value })}
                      style={{ display: "none" }}
                    />
                    <Button variant="contained" onClick={() => fileInputRef.current.click()}>
                      Upload Images
                    </Button>
                    <Box sx={{ display: "flex", marginTop: 2 }}>
                      {images.length > 0 ? (
                        images.map((url, index) => (
                          <div key={index} style={{ marginRight: "10px", position: "relative" }}>
                            <ImagePreview
                              src={
                                url ? `https://tpostapi.mytk.app${url}` : "/path/to/default/url.jpg"
                              }
                              alt={`Image ${index + 1}`}
                            />
                            <IconButton
                              onClick={() => handleRemoveImage(url)} // Supprime l'image lorsqu'on clique sur l'icône
                              style={{
                                position: "absolute",
                                top: "5px",
                                right: "5px",
                                backgroundColor: "rgba(0, 0, 0, 0.5)",
                                color: "white",
                              }}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </div>
                        ))
                      ) : (
                        <p>No images uploaded yet.</p>
                      )}
                    </Box>
                  </Box>
                  {error && <p style={{ color: "red" }}>{error}</p>}
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleClose} color="primary">
                    Cancel
                  </Button>

                  <Button onClick={handleSave} color="primary" disabled={isSubmitting}>
                    {isSubmitting ? "Saving..." : "Save"}
                  </Button>
                </DialogActions>
              </Dialog>

              <Dialog open={openConfirm} onClose={handleCloseConfirm}>
                <DialogTitle>Confirm Deletion</DialogTitle>
                <DialogContent>Are you sure you want to delete this post?</DialogContent>
                <DialogActions>
                  <Button onClick={handleCloseConfirm} color="primary">
                    Cancel
                  </Button>
                  <Button onClick={() => handleDelete(itemToDelete.id)} color="primary">
                    Delete
                  </Button>
                </DialogActions>
              </Dialog>
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>

      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          padding: "16px",
        }}
      >
        <Footer />
      </Box>
    </DashboardLayout>
  );
};

export default TableComponent;
