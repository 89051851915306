const getToken = () => {
  return localStorage.getItem("token");
};

export const getPosts = async (status = "") => {
  try {
    const token = getToken();
    let url;

    // Détermine l'URL en fonction du statut
    switch (status) {
      case "New":
        url = "https://tpostapi.mytk.app/api/post/new";
        break;
      case "In progress":
        url = "https://tpostapi.mytk.app/api/post/inProgress";
        break;
      case "Submitted":
        url = "https://tpostapi.mytk.app/api/post/submitted";
        break;
      case "Published":
        url = "https://tpostapi.mytk.app/api/post/published";
        break;
      case "Refused":
        url = "https://tpostapi.mytk.app/api/post/refused";
        break;
      default:
        url = "https://tpostapi.mytk.app/api/post/index"; // Endpoint pour tous les posts
        break;
    }

    const response = await fetch(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      throw new Error(`Network response was not ok: ${response.statusText}`);
    }

    const contentType = response.headers.get("content-type");
    const textResponse = await response.text();

    // Log des données pour débogage
    console.log("Response Text:", textResponse);

    if (contentType && contentType.includes("application/json")) {
      const result = JSON.parse(textResponse);
      return result.posts || []; // Retourne un tableau vide si "posts" n'existe pas
    } else {
      throw new Error("Response is not JSON");
    }
  } catch (error) {
    console.error("Error fetching posts:", error);
    throw error; // Propage l'erreur pour gestion ultérieure
  }
};

export const createPost = async (post) => {
  const token = getToken();
  const response = await fetch("https://tpostapi.mytk.app/api/post/create", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(post),
  });

  const text = await response.text();
  console.log("Response Status:", response.status);
  console.log("Response Text:", text);

  if (!response.ok) {
    throw new Error(`Erreur: ${response.status} - ${text}`);
  }

  return JSON.parse(text);
};

export const updatePost = async (id, post) => {
  try {
    const token = getToken();
    const response = await fetch("https://tpostapi.mytk.app/api/post/update", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ id, ...post }),
    });
    const text = await response.text();
    console.log("Response Status:", response.status);
    console.log("Response Text:", text);

    if (!response.ok) {
      throw new Error(`Erreur: ${response.status} - ${text}`);
    }
  } catch (error) {
    console.error(`Error updating post with id ${id}:`, error);
    throw error;
  }
};

export const deletePost = async (id) => {
  try {
    const token = getToken();
    const response = await fetch("https://tpostapi.mytk.app/api/post/delete", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ id }),
    });

    if (!response.ok) {
      const errorMessage = await response.text(); // Récupère le message d'er
      throw new Error(
        `Network response was not ok: ${response.statusText}. Server said: ${errorMessage}`
      );
    }

    return await response.json();
  } catch (error) {
    console.error("Error deleting post:", error);
    throw error;
  }
};
