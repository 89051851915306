const BASE_URL = "https://tpostapi.mytk.app/api/rolepermission";

const token = localStorage.getItem("token");

const RolePagePermissionService = {
  async getAllRolePermissions() {
    const response = await fetch(`${BASE_URL}/index`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    if (!response.ok) {
      throw new Error("Failed to fetch role permissions.");
    }
    const data = await response.json();
    return data.roles;
  },

  async getRolePermissionById(id) {
    const response = await fetch(`${BASE_URL}/show/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    if (!response.ok) {
      throw new Error("Failed to fetch role permission.");
    }
    const data = await response.json();
    return data.role;
  },

  async updatePermission(id, payload) {
    const response = await fetch(`${BASE_URL}/update/${id}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(payload),
    });
    if (!response.ok) {
      throw new Error("Failed to update permission.");
    }
    return await response.json();
  },

  async deleteRole(id) {
    const response = await fetch(`${BASE_URL}/delete`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ id }),
    });
    if (!response.ok) {
      throw new Error("Failed to delete role.");
    }
    const data = await response.json();
    return data.crs;
  },
};

export default RolePagePermissionService;
