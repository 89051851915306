import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import BasicLayout from "layouts/authentication/components/BasicLayout";
import PropTypes from "prop-types";
import bgImage from "assets/images/bg-sign-in-basic.jpeg";
import { getUserWithCompany } from "layouts/user/userService";

function Basic({ setRole, setIsAuthenticated }) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const [error, setError] = useState(null);
  const [isResettingPassword, setIsResettingPassword] = useState(false);
  const [resetSuccess, setResetSuccess] = useState(false);
  const [loading, setLoading] = useState(true); // État de chargement pour masquer la page temporairement
  const navigate = useNavigate(); // Fonction pour naviguer
  const handleSetRememberMe = () => setRememberMe(!rememberMe);

  useEffect(() => {
    const token = localStorage.getItem("token");
    const userRole = localStorage.getItem("userRole");

    if (token && userRole) {
      setRole(userRole);
      setIsAuthenticated(true);
      navigate("/dashboard"); // Redirige vers le dashboard si l'utilisateur est authentifié
    } else {
      navigate("/authentication/sign-in"); // Redirige vers la page de connexion si non authentifié
    }

    setLoading(false); // Arrête le chargement une fois la vérification terminée
  }, [setRole, setIsAuthenticated, navigate]);
  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      // Tentative de connexion de l'utilisateur
      const response = await fetch("https://tpostapi.mytk.app/api/login", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email, password }),
      });

      const data = await response.json();

      if (response.ok) {
        const token = data.token;
        const userId = data.user.id;
        const userRoleName = data.user.role_name.toUpperCase();

        // Stockage du token et des informations utilisateur dans le localStorage
        localStorage.setItem("token", token);
        localStorage.setItem("currentUserId", userId);
        localStorage.setItem("userRole", userRoleName);

        console.log("L'id de l'utilisateur :", userId);
        console.log("Rôle de l'utilisateur :", userRoleName);

        // Appel du service pour récupérer les informations de l'utilisateur et de sa compagnie
        const userWithCompany = await getUserWithCompany();

        console.log("Infos de l'utilisateur et de sa compagnie :", userWithCompany);

        // Vérification et stockage du logo de la compagnie si disponible
        if (userWithCompany.logo) {
          localStorage.setItem("userLogo", userWithCompany.logo);
          console.log("Le logo de l'utilisateur :", userWithCompany.logo);
        } else {
          console.log("Aucune compagnie trouvée pour l'utilisateur.");
        }

        // Mise à jour du rôle et de l'état d'authentification
        setRole(userRoleName);
        setIsAuthenticated(true);

        // Redirection en fonction du rôle de l'utilisateur
        if (
          userRoleName === "SUPER ADMIN" ||
          userRoleName === "ADMIN" ||
          userRoleName === "AUTHOR"
        ) {
          navigate("/dashboard");
        }
      } else {
        // Affichage d'une erreur si la connexion échoue
        setError(data.message || "Erreur lors de la connexion");
      }
    } catch (error) {
      // Gestion des erreurs en cas de problème avec l'API
      setError("Une erreur est survenue. Veuillez réessayer plus tard.");
      console.error("Erreur de connexion :", error);
    }
  };

  const handleResetPassword = async () => {
    try {
      const response = await fetch(`https://tpostapi.mytk.app/api/user/reset-password/${email}`, {
        method: "GET",
      });

      const data = await response.json();

      if (response.ok) {
        setResetSuccess(true);
      } else {
        setError(data.message || "Erreur lors de la réinitialisation du mot de passe");
      }
    } catch (error) {
      setError("Une erreur est survenue. Veuillez réessayer plus tard.");
    }
  };

  return (
    <BasicLayout image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            {isResettingPassword ? "Reset password" : "Sign in"}
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          {isResettingPassword ? (
            <MDBox component="form" role="form">
              <MDBox mb={2}>
                <MDInput
                  type="email"
                  label="Email"
                  fullWidth
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </MDBox>
              <MDBox mt={4} mb={1}>
                <MDButton variant="gradient" color="info" fullWidth onClick={handleResetPassword}>
                  Reset Password
                </MDButton>
              </MDBox>
              {resetSuccess && (
                <MDTypography variant="caption" color="success">
                  A reset email has been sent!
                </MDTypography>
              )}
              <MDBox mt={3} textAlign="center">
                <MDTypography
                  variant="button"
                  color="info"
                  onClick={() => setIsResettingPassword(false)}
                  sx={{ cursor: "pointer", textDecoration: "underline" }}
                >
                  Back to login
                </MDTypography>
              </MDBox>
            </MDBox>
          ) : (
            <MDBox component="form" role="form" onSubmit={handleSubmit}>
              <MDBox mb={2}>
                <MDInput
                  type="email"
                  label="Email"
                  fullWidth
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </MDBox>
              <MDBox mb={2}>
                <MDInput
                  type="password"
                  label="Password"
                  fullWidth
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </MDBox>
              <MDBox display="flex" alignItems="center" ml={-1}>
                <Switch checked={rememberMe} onChange={handleSetRememberMe} />
                <MDTypography
                  variant="button"
                  fontWeight="regular"
                  color="text"
                  onClick={handleSetRememberMe}
                  sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
                >
                  &nbsp;&nbsp;Remember me
                </MDTypography>
              </MDBox>
              <MDBox mt={4} mb={1}>
                <MDButton variant="gradient" color="info" fullWidth type="submit">
                  Sign in
                </MDButton>
              </MDBox>
              {error && (
                <MDBox mt={2} mb={1}>
                  <MDTypography variant="caption" color="error">
                    {error}
                  </MDTypography>
                </MDBox>
              )}
              <MDBox mt={3} textAlign="center">
                <MDTypography
                  variant="button"
                  color="info"
                  onClick={() => setIsResettingPassword(true)}
                  sx={{ cursor: "pointer", textDecoration: "underline" }}
                >
                  Forgot your password?
                </MDTypography>
              </MDBox>
            </MDBox>
          )}
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

Basic.propTypes = {
  setRole: PropTypes.func.isRequired,
  setIsAuthenticated: PropTypes.func.isRequired,
};

export default Basic;
