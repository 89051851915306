import React, { useState } from "react";
import { useNavigate } from "react-router-dom"; // Importer useNavigate
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import CoverLayout from "layouts/authentication/components/CoverLayout";
import bgImage from "assets/images/bg-reset-cover.jpeg";

function VerifyAndResetPassword() {
  const [email, setEmail] = useState("");
  const [code, setCode] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [message, setMessage] = useState("");
  const navigate = useNavigate(); // Hook pour la redirection

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validation locale
    if (newPassword !== confirmPassword) {
      setMessage("Passwords don't match!");
      return;
    }

    try {
      const response = await fetch("https://tpostapi.mytk.app/api/user/verify-code", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email, // Ajout de l'email dans la requête
          code,
          password: newPassword,
          password_confirmation: confirmPassword,
        }),
      });

      const data = await response.json();
      console.log("Réponse du serveur:", data);

      if (response.ok) {
        setMessage("Password reset successfully!");
        // Rediriger vers la page de connexion après un succès
        navigate("/authentication/sign-in"); // Utiliser navigate pour rediriger
      } else {
        setMessage(data.message || "Une erreur s'est produite.");
      }
    } catch (error) {
      setMessage("Erreur réseau. Veuillez réessayer.");
    }
  };

  return (
    <CoverLayout coverHeight="50vh" image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="success"
          mx={2}
          mt={-3}
          py={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h3" fontWeight="medium" color="white" mt={1}>
            Reset Password
          </MDTypography>
          <MDTypography display="block" variant="button" color="white" my={1}>
            Please enter the code received by email and set a new password.
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          {message && (
            <MDTypography variant="button" color="error" textAlign="center" mb={2}>
              {message}
            </MDTypography>
          )}
          <MDBox component="form" role="form" onSubmit={handleSubmit}>
            <MDBox mb={4}>
              <MDInput
                type="email"
                label="Email"
                variant="standard"
                fullWidth
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </MDBox>

            <MDBox mb={4}></MDBox>
            <MDBox mb={4}>
              <MDInput
                type="text"
                label="Verification code"
                variant="standard"
                fullWidth
                value={code}
                onChange={(e) => setCode(e.target.value)}
                required
              />
            </MDBox>
            <MDBox mb={4}>
              <MDInput
                type="password"
                label="New Password"
                variant="standard"
                fullWidth
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                required
              />
            </MDBox>
            <MDBox mb={4}>
              <MDInput
                type="password"
                label="Confirm password"
                variant="standard"
                fullWidth
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                required
              />
            </MDBox>
            <MDBox mt={6} mb={1}>
              <MDButton type="submit" variant="gradient" color="info" fullWidth>
                Reset Password
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </CoverLayout>
  );
}

export default VerifyAndResetPassword;
