const BASE_URL = "https://tpostapi.mytk.app/api/page";

const getToken = () => {
  return localStorage.getItem("token");
};

const PageManagementService = {
  async getAllRolePermissions() {
    const token = getToken();
    const response = await fetch(`${BASE_URL}/index`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`, // Ajoute le token d'authentification
      },
    });
    if (!response.ok) {
      throw new Error("Failed to fetch pages.");
    }
    const data = await response.json();
    return data.pages;
  },

  async createPermission(payload) {
    const token = getToken();
    const response = await fetch(`${BASE_URL}/create`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`, // Ajoute le token d'authentification
      },
      body: JSON.stringify(payload),
    });
    if (!response.ok) {
      throw new Error("Failed to create page.");
    }
    return await response.json();
  },

  async getRolePermissionById(id) {
    const token = getToken();
    const response = await fetch(`${BASE_URL}/show/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (!response.ok) {
      throw new Error("Failed to fetch page.");
    }
    const data = await response.json();
    return data.role;
  },

  async updatePermission(id, payload) {
    const token = getToken();
    const response = await fetch(`${BASE_URL}/update/${id}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`, // Ajoute le token d'authentification
      },
      body: JSON.stringify(payload),
    });
    if (!response.ok) {
      throw new Error("Failed to update page.");
    }
    return await response.json();
  },

  async deleteRole(id) {
    const token = getToken();
    const response = await fetch(`${BASE_URL}/delete/${id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`, // Ajoute le token d'authentification
      },
      body: JSON.stringify({ id }),
    });
    if (!response.ok) {
      throw new Error("Failed to delete role.");
    }
    const data = await response.json();
    return data.crs;
  },
};

export default PageManagementService;
